import React from "react";
import { BigButtonContainer } from "./styles";

interface BigButtonProps {
    children: string;
    onClick: () => void;
}

export const BigButton = ({children, onClick}: BigButtonProps) => {
    return (
        <BigButtonContainer onClick={() => onClick()}>
            <p>{children}</p>
        </BigButtonContainer>
    );
}