import styled from 'styled-components';

export const MainButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 50px;
    border-radius: 8px;

    max-width: 200px;
    background-color: ${props => props.theme.palette.primaryColor};

    p {
        font-size: 20px;
        color: white;
        font-weight: bold;
    }

    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }
`;
