import React from "react";
import { QuestionContainer } from "./styles";

interface QuestionBoxProps {
    question: string;
}

export const QuestionBox = ({
    question
}: QuestionBoxProps) => {
    return (
        <QuestionContainer>
            {question}
        </QuestionContainer>
    );
}


