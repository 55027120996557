import {
	Routes,
	Route,
	BrowserRouter as Router,
} from "react-router-dom";
import { Home, Question1, Question2, Question3, Question4, Result } from "../pages";

export const MainRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route path="*" element={<Home/>} />
                <Route path="/question1" element={<Question1/>} />
                <Route path="/question2" element={<Question2/>} />
                <Route path="/question3" element={<Question3/>} />
                <Route path="/question4" element={<Question4/>} />
                <Route path="/result" element={<Result/>} />
            </Routes>
        </Router>
    )
}