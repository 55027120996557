import React from "react";
import { ContainerGradientBackground, DescriptionText, ImageLogo } from "./styles";
import logo from "../../assets/logo.png";
import { MainButton } from "../../components";
import { useNavigate } from "react-router-dom";


export const Home = () => {

    const navigate = useNavigate();

    return (
        <ContainerGradientBackground>
            <ImageLogo src={logo} alt={"logo"}/>
            <h1>Qual o seu espírito animal?</h1>
            <DescriptionText>Descubra qual tipo de cachorro você seria respondendo perguntas sobre a sua personalidade!</DescriptionText>
            <MainButton onClick={() => navigate("/question1")}>Começar</MainButton>
            <h2>Labrador Retriever, Pastor Alemão, Golden Retriever, Bulldog Francês, Poodle, Beagle, Rottweiler, Yorkshire Terrier, Boxer, Dachshund, Bulldog Inglês, Shih Tzu, Pug, Doberman, Chihuahua, Schnauzer Miniatura, Pomeranian, Pastor de Shetland, Cocker Spaniel Americano, Buldogue Americano, Husky Siberiano, Cavalier King Charles Spaniel, Australian Shepherd, Maltês, Weimaraner, Akita, Border Collie, Boston Terrier, Bull Terrier, Basenji, Bichon Frisé, Cane Corso, Setter Irlandês, West Highland White Terrier, Pointer Alemão de Pelo Curto, São Bernardo, Bernese Mountain Dog, Vizsla, Jack Russell Terrier, Staffordshire Bull Terrier, Cão de Água Português, Bloodhound, Alaskan Malamute, Airedale Terrier, Greyhound, Chow Chow, American Pit Bull Terrier, Whippet, Havanês, Samoieda</h2>
        </ContainerGradientBackground>
    );
}
