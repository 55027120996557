import axios from "axios";
import { DogInfo } from "../types/api/DogInfo";

export const dogApi = axios.create({
    // baseURL: process.env.REACT_DOG_BASE_URL,
    baseURL: "https://api.thedogapi.com/",
    timeout: 30000,
    headers: {
        // "x-api-key": process.env.REACT_DOG_API_KEY
        "x-api-key": "live_d4l6k9Uam6RNiaiOx80HYIzLsoiewpPQ6Fe8XXG7THKuY25RVazA0bHfBDBJ1D6Q"
    },
});


export const getRandomDog = () => {
    console.log("OPAA" + JSON.stringify(process.env));
    return new Promise<DogInfo>((resolve, reject) => {
        dogApi.get("v1/images/search?size=med&mime_types=jpg&format=json&has_breeds=true&order=RANDOM&page=0&limit=1")
            .then((response) => {
                resolve(response.data[0]);
            })
            .catch((error) => {
                reject(error);
            });
    });
}