import React from 'react';
import { MainRoutes } from './routes';
import { ThemeProvider } from 'styled-components';
import { DarkTheme } from './constants/DarkTheme';

function App() {
  return (
    <ThemeProvider theme={DarkTheme}>
      <MainRoutes />
    </ThemeProvider>
  );
}

export default App;
