import React from "react";
import { ContainerGradientBackground, ImageLogo } from "./styles";
import logo from "../../assets/logo.png";
import { BigButton } from "../../components";
import { useNavigate } from "react-router-dom";
import { QuestionBox } from "../../components/QuestionBox";


export const Question2 = () => {

    const navigate = useNavigate();

    return (
        <ContainerGradientBackground>
            <ImageLogo src={logo} alt={"logo"}/>
            <h2>Responda a pergunta para que o algoritmo encontra sua personalidade canina</h2>
            <QuestionBox 
                question="Se você fosse um cão, o que você faria se encontrasse um gato no seu quintal?"
            />
            <h2>Escolha uma opção:</h2>
            <BigButton onClick={() => navigate("/question3")}>Correria atrás como se minha vida dependesse disso</BigButton>
            <BigButton onClick={() => navigate("/question3")}>Tentaria fazer amizade com ele</BigButton>
            <BigButton onClick={() => navigate("/question3")}>Iria ignorar</BigButton>
        </ContainerGradientBackground>
    );
}
