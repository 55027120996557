import React from "react";
import { ContainerGradientBackground, ImageLogo } from "./styles";
import logo from "../../assets/logo.png";
import { BigButton } from "../../components";
import { useNavigate } from "react-router-dom";
import { QuestionBox } from "../../components/QuestionBox";


export const Question1 = () => {

    const navigate = useNavigate();

    return (
        <ContainerGradientBackground>
            <ImageLogo src={logo} alt={"logo"}/>
            <h2>Responda a pergunta para que o algoritmo encontra sua personalidade canina</h2>
            <QuestionBox 
                question="Se você fosse um cão, qual seria a sua reação ao ouvir a palavra “Passear”?"
            />
            <h2>Escolha uma opção:</h2>
            <BigButton onClick={() => navigate("/question2")}>Pulo de alegria e corro para a porta</BigButton>
            <BigButton onClick={() => navigate("/question2")}>Fico calmo</BigButton>
            <BigButton onClick={() => navigate("/question2")}>Fico indiferente</BigButton>
        </ContainerGradientBackground>
    );
}
