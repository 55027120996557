import React from "react";
import { ContainerGradientBackground, ImageLogo } from "./styles";
import logo from "../../assets/logo.png";
import { BigButton } from "../../components";
import { useNavigate } from "react-router-dom";
import { QuestionBox } from "../../components/QuestionBox";


export const Question3 = () => {

    const navigate = useNavigate();

    return (
        <ContainerGradientBackground>
            <ImageLogo src={logo} alt={"logo"}/>
            <h2>Responda a pergunta para que o algoritmo encontra sua personalidade canina</h2>
            <QuestionBox 
                question="Se você fosse um cão, qual seria seu brinquedo favorito?"
            />
            <h2>Escolha uma opção:</h2>
            <BigButton onClick={() => navigate("/question4")}>Bolinha que faz barulho quando aperta</BigButton>
            <BigButton onClick={() => navigate("/question4")}>Osso gigante que posso roer por horas</BigButton>
            <BigButton onClick={() => navigate("/question4")}>Um travesseiro macio</BigButton>
        </ContainerGradientBackground>
    );
}
