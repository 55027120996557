import React from "react";
import { MainButtonContainer } from "./styles";

interface MainButtonProps {
    children: string;
    onClick: () => void;
}

export const MainButton = ({children, onClick}: MainButtonProps) => {
    return (
        <MainButtonContainer onClick={() => onClick()}>
            <p>{children}</p>
        </MainButtonContainer>
    );
}