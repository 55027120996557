import styled from "styled-components";
import { DEVICE } from "../../constants/ScreenSizes";

export const ContainerGradientBackground = styled.div`
    background: linear-gradient(to bottom right, ${props => props.theme.palette.backgroundGradient1}, ${props => props.theme.palette.backgroundGradient2});
    width: 100%;
    height: 100vh;
    min-height: 1000px;
    display: flex;
    align-items: center;
    flex-direction: column;

    h2 {
        color: white;
        font-size: 24px;
        margin-top: 20px;
        max-width: 500px;
        text-align: center;
        font-weight: 200;

        @media ${DEVICE.TABLET} {
            font-size: 18px;
            width: 80%;
        }
    }
`;

export const ImageLogo = styled.img`
    height: 200px;

    @media ${DEVICE.TABLET} {
        height: 150px;
    }
`;

export const DescriptionText = styled.p`
    font-size: 20px;
    color: white;
    margin-top: 20px;
    margin-bottom: 50px;

    text-align: center;
    width: 90%;
`;