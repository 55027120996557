import styled from "styled-components";
import { DEVICE } from "../../constants/ScreenSizes";

export const ContainerGradientBackground = styled.div`
    background: linear-gradient(to bottom right, ${props => props.theme.palette.backgroundGradient1}, ${props => props.theme.palette.backgroundGradient2});
    width: 100%;
    height: 100vh;
    min-height: 1500px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    color: white;

    h1 {
        width: 90%;
        font-size: 28px;
        text-align: center;
    }

    h2 {
        width: 90%;
        max-width: 500px;
        font-size: 20px;
        text-align: center;
        font-weight: 100;
        margin-top: 50px;
    }
`;

export const ImageLogo = styled.img`
    height: 200px;


    @media ${DEVICE.TABLET} {
        height: 150px;
    }
`;

export const DescriptionText = styled.p`
    font-size: 20px;
    color: white;
    margin-top: 20px;
    margin-bottom: 50px;

    text-align: center;
    width: 80%;
`;