import styled from "styled-components";
import { DEVICE } from "../../constants/ScreenSizes";

export const ContainerGradientBackground = styled.div`
    background: linear-gradient(to bottom right, ${props => props.theme.palette.backgroundGradient1}, ${props => props.theme.palette.backgroundGradient2});
    width: 100%;
    height: 100vh;
    min-height: 1500px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 50px;
    box-sizing: border-box;
    align-items: center;

    h2 {
        color: white;
        font-size: 24px;
        margin-top: 20px;
        max-width: 400px;
        text-align: center;
        font-weight: 200;
        width: 90%;
    }

    h3 {
        color: white;
    }
`;

export const ImageLogo = styled.img`
    height: 200px;

    @media ${DEVICE.TABLET} {
        height: 150px;
    }
`;

export const DescriptionText = styled.p`
    font-size: 20px;
    color: white;
    margin-top: 20px;
    margin-bottom: 50px;

    text-align: center;
    width: 90%;
`;

export const DogImage = styled.img`
    width: 100%;
    max-width: 400px;
    margin-top: 20px;
    border-radius: 10px;
`;

export const DogInfosContainer = styled.div`
    color: white;
    justify-content: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    p {
        font-size: 14px;
        margin-top: 10px;
        width: 90%;
    }
`;