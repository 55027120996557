import React from "react";
import { ContainerGradientBackground, ImageLogo } from "./styles";
import logo from "../../assets/logo.png";
import { BigButton } from "../../components";
import { useNavigate } from "react-router-dom";
import { QuestionBox } from "../../components/QuestionBox";


export const Question4 = () => {

    const navigate = useNavigate();

    return (
        <ContainerGradientBackground>
            <ImageLogo src={logo} alt={"logo"}/>
            <h2>Responda a pergunta para que o algoritmo encontra sua personalidade canina</h2>
            <QuestionBox 
                question="Qual a sua ideia de dia perfeito?"
            />
            <h2>Escolha uma opção:</h2>
            <BigButton onClick={() => navigate("/result")}>Correr e brincar sem parar o dia todo</BigButton>
            <BigButton onClick={() => navigate("/result")}>Dormir o dia inteiro</BigButton>
            <BigButton onClick={() => navigate("/result")}>Patrulhar o quintal e proteger a casa</BigButton>
        </ContainerGradientBackground>
    );
}
