import React, { useEffect, useState } from "react";
import { ContainerGradientBackground, DogImage, DogInfosContainer, ImageLogo } from "./styles";
import logo from "../../assets/logo.png";
import { DogInfo } from "../../types/api/DogInfo";
import { getRandomDog } from "../../services/api";
import { QuestionBox } from "../../components/QuestionBox";
import { MainButton } from "../../components";
import { useNavigate } from "react-router-dom";
import { Bounce } from "react-activity";
import "react-activity/dist/library.css";

export const Result = () => {

    const steps = ["O algoritmo está analisando suas respostas", "Estamos quase lá", "Pronto! Encontramos o cachorro ideal!"];
    const [currentStep, setCurrentStep] = useState(0);
    const [dogInfo, setDogInfo] = useState<null | DogInfo>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentStep((prev) => {
                if (prev === steps.length - 1) {
                    setLoading(false);
                    clearInterval(interval);
                    return prev;
                }
                return prev + 1
            });
        }, 2000);

        return () => {
            clearInterval(interval);
        }
    }, [steps.length]);


    useEffect(() => {
        getRandomDog()
            .then((response) => {
                setDogInfo(response);
                setError(false);
            }).catch(() => {
                setError(true);
                setLoading(false);
            });
    }, []);


    return (

        <ContainerGradientBackground>
            <ImageLogo src={logo} alt={"logo"} />
            <h2>{steps[currentStep]}</h2>

            {
                loading && (
                    <>
                        <Bounce size={20} color={"white"} />
                    </>
                )
            }

            {
                error && (
                    <>
                        <h3>Erro ao carregar os dados</h3>
                        <br />
                    </>
                )
            }

            {
                dogInfo && !loading && !error && (
                    <DogInfosContainer>
                        <DogImage src={dogInfo.url} alt={dogInfo.breeds[0].name} />
                        <br />
                        <QuestionBox question={dogInfo.breeds[0].name} />
                        <p><b>Temperamento: </b>{dogInfo.breeds[0].temperament}</p>
                        <p><b>Característica da raça: </b>{dogInfo.breeds[0].bred_for}</p>
                        <p><b>Grupo: </b>{dogInfo.breeds[0].breed_group}</p>
                        <p><b>Temperamento: </b>{dogInfo.breeds[0].temperament}</p>
                        <p><b>Peso (KG): </b>{dogInfo.breeds[0].weight.metric}</p>
                        <p><b>Altura (Cm): </b>{dogInfo.breeds[0].height.metric}</p>
                    </DogInfosContainer>
                )
            }
            {
                !loading && (
                    <>
                        <br />
                        <MainButton onClick={() => navigate("/")} >Jogar Novamente</MainButton>
                    </>
                )
            }
        </ContainerGradientBackground>
    );
}