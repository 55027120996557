import { DefaultTheme } from "styled-components";

export const DarkTheme: DefaultTheme = {
    palette: {
        primaryColor: "#e2a853",
        secondaryColor: "#9e2726",
        tertiaryColor: "#1e4877",
    
        backgroundGradient1: "#15737e",
        backgroundGradient2: "#15567e"
    }
} 